<template>
  <base-wrapper>
    <div class="grid grid-cols-1 gap-4">
      <base-card title="Tarik Data Komisi">
        <div
          class="grid grid-cols-1 gap-4"
        >
          <div class="col-span-full">
            <base-button :loading="loadingExport" @click="confirming = true">{{
              loadingExport ? loadingExportText : 'Tarik'
            }}</base-button>
          </div>
          <alert v-if="success.visible" @dismiss="onDismissSuccess">
            Data komisi berhasi ditarik, klik
            <a :href="success.url" target="_blank" class="font-bold underline"
              >download</a
            >
            untuk melihat file hasil.
          </alert>
        </div>
      </base-card>
      <base-card title="Riwayat Export">
        <template #action>
          <div class="flex items-center gap-x-2">
            <base-select :options="yearOptions" :shadow="false" :expand="false" v-model="filterExport.year" @change="loadOfficeCommissionExports" />
            <base-select :options="monthOptions" :shadow="false" :expand="false" v-model="filterExport.month" @change="loadOfficeCommissionExports" />
          </div>
        </template>
        <datatable
          :columns="officeCommissionExportsColumns"
          :scroll-x="false"
          :total="officeCommissionExports.meta.page.total"
          :perPage="officeCommissionExports.meta.page.perPage"
          :currentPage="officeCommissionExports.meta.page.currentPage"
          :meta="officeCommissionExports.meta"
          @pagechanged="onPageOfficeCommisionExportsChanged"
        >
          <template v-slot:tbody="{ classes }">
            <tr
              v-for="history in officeCommissionExports.data"
              :key="history.id"
              :class="[classes.tr, 'bg-white hover:bg-green-100']"
            >
              <td :class="[classes.td]">
                {{ history.attributes.createdAt | formatDate }}
              </td>
              <td :class="[classes.td]">
                {{ history.attributes.period ?? '-' }}
              </td>
              <td :class="[classes.td]">
                <a v-if="history.attributes.status === 'finished'" class="font-bold text-green-600" target="_blank" :href="history.attributes.file_url">Download</a>
                <span v-else>-</span>
              </td>
              <td :class="[classes.td]">
                <base-badge :color="getStatusColor(history.attributes.status)">{{ getStatusText(history.attributes.status) }}</base-badge>
              </td>
            </tr>
          </template>
        </datatable>
      </base-card>
      <base-card title="Data Komisi">
        <template #action>
          <div class="flex items-center gap-x-2">
            <base-select :options="yearOptions" :shadow="false" :expand="false" v-model="filterCommission.year" @change="loadOfficeCommissions" />
            <base-select :options="monthOptions" :shadow="false" :expand="false" v-model="filterCommission.month" @change="loadOfficeCommissions" />
            <base-select :options="statusOptions" :shadow="false" :expand="false" v-model="filterCommission.status" @change="loadOfficeCommissions" />
            <base-input :shadow="false" type="search" placeholder="Cari Tempat Bonus" debounce v-model="filterCommission.bonus_pickup_office" @native-input="loadOfficeCommissions" />
          </div>
        </template>
        <datatable
          :columns="officeCommissionsColumns"
          :scroll-x="false"
          :total="officeCommissions.meta.page.total"
          :perPage="officeCommissions.meta.page.perPage"
          :currentPage="officeCommissions.meta.page.currentPage"
          :meta="officeCommissions.meta"
          @pagechanged="onPageOfficeCommisionsChanged"
        >
          <template v-slot:tbody="{ classes }">
            <tr
              v-for="officeCommission in officeCommissions.data"
              :key="officeCommission.id"
              :class="[classes.tr, 'bg-white hover:bg-green-100']"
            >
              <td :class="[classes.td]">
                <p class="font-bold text-gray-900">
                  {{ officeCommission.attributes.office_code }}
                </p>
              </td>
              <td :class="[classes.td]">
                {{ officeCommission.attributes.office_name }}
              </td>
              <td :class="[classes.td]">
                {{ officeCommission.attributes.stage }}
              </td>
              <td :class="[classes.td, 'text-right']">
                {{ officeCommission.attributes.bonus | toCurrency }}
              </td>
              <td :class="[classes.td]">
                {{ officeCommission.attributes.bonus_pickup_office }}
              </td>
              <td :class="[classes.td]">
                {{ officeCommission.attributes.period }}
              </td>
              <td :class="[classes.td]">
                <base-badge :color="officeCommission.attributes.is_retrieved ? 'green' : 'yellow'">{{ officeCommission.attributes.bonus_pickup_office === 'NUERA' ? officeCommission.attributes.is_retrieved ? 'Diambil' : 'Belum Diambil' : 'NASA Reguler' }}</base-badge>
              </td>
            </tr>
            <tr>
              <td :class="[classes.td, 'text-right']" colspan="3">Total Bonus</td>
              <td :class="[classes.td, 'text-right']">{{ officeCommissions.meta.total_bonus | toCurrency }}</td>
            </tr>
            <tr>
              <td :class="[classes.td, 'text-right']" colspan="3">Total Komisi</td>
              <td :class="[classes.td, 'text-right']">{{ officeCommissions.meta.page.total | toCurrency }}</td>
            </tr>
          </template>
        </datatable>
      </base-card>
    </div>
    <base-confirm
      message="Apakah anda yakin akan menarik data komisi?"
      :visible="confirming"
      @close="confirming = false"
      @confirmed="onConfirmed"
    ></base-confirm>
    <loading v-if="loadingPage" />
  </base-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { requestMixin } from '@/mixins/request/request';
import BaseConfirm from '@/components/base/BaseConfirm.vue';
import Alert from '@/components/base/Alert.vue';
import { generatePastYear, translateMonthNumber, parseDate } from '@/utils/date'

export default {
  mixins: [requestMixin],
  components: { BaseConfirm, Alert },
  data() {
    return {
      loadingExport: false,
      loadingExportText: 'Menunggu Diproses',
      loadingOfficeCommissions: false,
      loadingOfficeCommissionExports: false,
      loadingCurrentPeriod: false,
      confirming: false,
      success: {
        visible: false,
        url: null,
      },
      filterExport: {
        year: parseDate().format('YY'),
        month: parseDate().format('MM')
      },
      filterCommission: {
        year: parseDate().format('YY'),
        month: parseDate().format('MM'),
        bonus_pickup_office: null,
        status: null,
      },
      officeCommissionExports: {
        data: [],
        meta: {
          page: {},
        },
      },
      officeCommissions: {
        data: [],
        meta: {
          page: {},
        },
      },
      prevPeriod: null
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    officeCommissionsColumns() {
      return [
        { id: 'code', name: 'Kode' },
        { id: 'name', name: 'Name' },
        { id: 'stage', name: 'Jenjang' },
        { id: 'bonus', name: 'Total Bonus', theadClass: 'text-right' },
        { id: 'bonus_pickup', name: 'Tempat Bonus' },
        { id: 'period', name: 'Periode' },
        { id: 'is_retrieved', name: 'Status' }
      ]
    },
    officeCommissionExportsColumns() {
      return [
        { id: 'createdAt', name: 'Tanggal' },
        { id: 'period', name: 'Periode' },
        { id: 'download', name: 'Download' },
        { id: 'status', name: 'Status' }
      ]
    },
    loadingPage() {
      return this.loadingOfficeCommissions || this.loadingOfficeCommissionExports || this.loadingCurrentPeriod
    },
    statusOptions() {
      return [
        { key: 'all', value: null, 'label': 'Semua Status' },
        { key: 'retrieved', value: true, 'label': 'Diambil' },
        { key: 'not_retrieved', value: false, 'label': 'Belum Diambil' }
      ]
    },
    yearOptions() {
      return generatePastYear().map(year => ({
        key: year,
        value: year.toString().slice(2),
        label: year
      }))
    },
    monthOptions() {
      return Array.from({ length: 12 }, (_, i) => ({
        key: i + 1,
        value: `${i + 1}`.padStart(2, '0'),
        label: translateMonthNumber(i + 1)
      }))
    }
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    onDismissSuccess() {
      this.success.visible = false;
    },
    async onConfirmed() {
      this.confirming = false;

      this.startLoading();

      const [, error] = await this.request(
        '/api/v1/office-commissions/-actions/export',
        {
          method: 'post'
        }
      );

      if (error) {
        this.createAlert({
          data: this.getRequestErrorMessage(error),
          status: 'error',
        });
      }
    },
    onPageOfficeCommisionsChanged(page) {
      this.loadOfficeCommissions({
        'page[number]': page
      })
    },
    onPageOfficeCommisionExportsChanged(page) {
      this.loadOfficeCommissionExports({
        'page[number]': page
      })
    },
    async loadData() {
      this.loadOfficeCommissions()
      this.loadOfficeCommissionExports()
    },
    async loadCurrentMitraPeriod() {
      this.loadingCurrentPeriod = true

      const [currentMitraPeriod] = await this.request('/api/v1/mitra-periods/-actions/get-previous-period')

      this.prevPeriod = currentMitraPeriod

      this.setFilterFromCurrentPeriod()

      this.loadingCurrentPeriod = false
    },
    async loadOfficeCommissions(params) {
      this.loadingOfficeCommissions = true;

      const [res, error] = await this.request('/api/v1/office-commissions', {
        params: {
          'page[size]': 5,
          'filter[period_code]': `${this.filterCommission.month}${this.filterCommission.year}`,
          'filter[bonus_pickup_office]': this.filterCommission.bonus_pickup_office || null,
          'filter[is_retrieved]': this.filterCommission.status,
          ...params,
        },
      });

      if (!error) {
        this.officeCommissions = res;
      }

      this.loadingOfficeCommissions = false;
    },
    async loadOfficeCommissionExports(params) {
      this.loadingOfficeCommissionExports = true;

      const [res, error] = await this.request('/api/v1/office-commission-exports', {
        params: {
          'page[size]': 5,
          'sort': '-id',
          'filter[period_code]': `${this.filterExport.month}${this.filterExport.year}`,
          ...params,
        },
      });

      if (!error) {
        this.officeCommissionExports = res;
      }

      this.loadingOfficeCommissionExports = false;
    },
    startLoading() {
      this.loadingExport = true;
      this.loadingExportText = 'Menunggu Diproses'
    },
    stopLoading() {
      this.loadingExport = false;
    },
    addListener() {
      const userChannel = this.$pusher.subscribe(
        `private-App.Models.User.${this.me.id}`
      );

      userChannel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', (e) => {
        if (e.type === 'App\\Notifications\\CommissionExported') {
          if (e.status === 'processing') {
            this.loadingExportText = 'Sedang Diproses'
          } else if (e.status === 'failed') {
            this.stopLoading()

            this.createAlert({
              data: 'Gagal menarik data',
              status: 'error'
            })
          } else if (e.status === 'finished') {
            this.stopLoading()

            this.success.visible = true
            this.success.url = e.file_url

            this.filterCommission.status = null
            
            this.setFilterFromCurrentPeriod()

            this.filterCommission.bonus_pickup_office = null

            this.loadData()
          }
        }
      })
    },
    removeListener() {
      this.$pusher.unsubscribe(
        `private-App.Models.User.${this.me.id}`
      );
    },
    getStatusText(status) {
      return {
        pending: 'Pending',
        processing: 'Sedang Diproses',
        failed: 'Gagal',
        finished: 'Selesai'
      }[status]
    },
    getStatusColor(status) {
      return {
        pending: 'yellow',
        processing: 'blue',
        failed: 'red',
        finished: 'green'
      }[status]
    },
    setFilterFromCurrentPeriod() {
      const date = parseDate(this.prevPeriod.data.attributes.end_date)
      
      this.filterCommission.year = date.format('YY')
      this.filterCommission.month = date.format('MM')
      
      this.filterExport.year = date.format('YY')
      this.filterExport.month = date.format('MM')
    }
  },
  async created() {
    await this.loadCurrentMitraPeriod()

    this.loadData()
    this.addListener()
  },
  beforeRouteLeave(to, from, next) {
    this.removeListener()

    next()
  }
};
</script>
